import React, { useState, useEffect, useLayoutEffect } from "react";
import { Redirect } from 'react-router';
import { Header } from "../../common/Header";
import {
    MDBBtn,
    MDBFreeBird,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBCardText,
    MDBCol,
    MDBContainer,
    MDBFormInline,
    MDBIcon,
    MDBInput,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBEdgeHeader,
    MDBCardTitle,
    MDBListGroup,
    MDBListGroupItem,
    MDBView,
    MDBMask,
    MDBNotification,
    MDBBadge,
    MDBCardFooter,
    MDBCardGroup,
    MDBNav,
    MDBNavLink,
    MDBNavItem,
    MDBTabContent,
    MDBTabPane,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem

} from "mdbreact";
import { MDBPopover, MDBPopoverBody, MDBPopoverHeader } from 'mdb-react-ui-kit';
import { useHistory } from "react-router";
import "./style.css"
import Charts from "react-apexcharts";
import { Chart } from "../common/VulnChart"
import { CategoryChart } from "../common/VulnCatChart"
import { OrgChart } from "../common/OrgChart"
import { GradeChart } from "../common/GradeChart"
import { fetchData } from '../../services/apiConfig'
import { TimeSeries } from '../common/TimeSeries'
export const Home = (props: any) => {
    const [pentestSeries, setpentestSeries] = useState([])
    const [domainSeries, setdomainSeries] = useState([])
    const [servicesSeries, setservicesSeries] = useState([])
    const [darkeyeSeries, setdarkeyeSeries] = useState([])
    const [pentestSeriesCategory, setpentestSeriesCategory] = useState([])
    const [domainsSeriesCategory, setdomainsSeriesCategory] = useState([])
    const [servicesSeriesCategory, setservicesSeriesCategory] = useState([])
    const [darkeyeSeriesCategory, setdarkeyeSeriesCategory] = useState([])
    const [pentestSeriesCategoryCount, setpentestSeriesCategoryCount] = useState([])
    const [domiansSeriesCategoryCount, setdomiansSeriesCategoryCount] = useState([])
    const [servicesSeriesCategoryCount, setservicesSeriesCategoryCount] = useState([])
    const [darkeyeSeriesCategoryCount, setdarkeyeSeriesCategoryCount] = useState([])
    const [activeItem, setactiveItem] = useState("1")
    const [signature, setSignature] = useState('');
    const token = localStorage.getItem("token");
    // const [domains, setDomain] = useState({
    //     availableDomains: 0,
    //     lastSynced: "July 02 2021, 20:57:13",
    //     nonReachableDomains: 0,
    //     domains: [],
    //     reachableDomains: 0
    // });
    // const [assets, setAssets] = useState(props.asset);

    console.log(props)

    useEffect(() => {
        let pentestSeries: any;
        let domainSeries: any;
        let servicesSeries: any;
        let darkeyeSeries: any

        let pentestSeriesCategory: any;
        let pentestSeriesCategoryCount: any;

        let domainsSeriesCategory: any;
        let domiansSeriesCategoryCount: any;

        let servicesSeriesCategory: any;
        let servicesSeriesCategoryCount: any;

        let darkeyeSeriesCategory: any;
        let darkeyeSeriesCategoryCount: any;

        if (props.Kpi.metrics.vapt.count !== undefined) {
            pentestSeries = [props.Kpi.metrics.vapt.count.criticalCount, props.Kpi.metrics.vapt.count.highCount, props.Kpi.metrics.vapt.count.mediumCount, props.Kpi.metrics.vapt.count.lowCount]
            setpentestSeries(pentestSeries)
            let sortable: any = [];
            for (const [key, value] of Object.entries(props.Kpi.metrics.vapt.categories)) {
                sortable.push([key, value]);
            }
            sortable = sortable.sort((a: any, b: any) => {
                return b[1] - a[1];
            });
            if (sortable.length > 0) {
                pentestSeriesCategory = [(sortable[0] as any)[0], (sortable[1] as any)[0], (sortable[2] as any)[0]];
                setpentestSeriesCategory(pentestSeriesCategory);
                pentestSeriesCategoryCount = [(sortable[0] as any)[1], (sortable[1] as any)[1], (sortable[2] as any)[1]];
                setpentestSeriesCategoryCount(pentestSeriesCategoryCount);
            }
        }
        if (props.Kpi.metrics.domains.count !== undefined) {
            domainSeries = [props.Kpi.metrics.domains.count.criticalCount, props.Kpi.metrics.domains.count.highCount, props.Kpi.metrics.domains.count.mediumCount, props.Kpi.metrics.domains.count.lowCount]
            setdomainSeries(domainSeries)
            let sortableDomains: any = [];
            for (const [key, value] of Object.entries(props.Kpi.metrics.domains.categories)) {
                sortableDomains.push([key, value]);
            }
            sortableDomains.sort((a: any, b: any) => {
                return b[1] - a[1];
            });
            domainsSeriesCategory = [(sortableDomains[0] as any)[0], (sortableDomains[1] as any)[0], (sortableDomains[2] as any)[0]];
            setdomainsSeriesCategory(domainsSeriesCategory);
            domiansSeriesCategoryCount = [(sortableDomains[0] as any)[1], (sortableDomains[1] as any)[1], (sortableDomains[2] as any)[1]];
            setdomiansSeriesCategoryCount(domiansSeriesCategoryCount);

        }
        if (props.Kpi.metrics.services.count !== undefined) {
            servicesSeries = [props.Kpi.metrics.services.count.criticalCount, props.Kpi.metrics.services.count.highCount, props.Kpi.metrics.services.count.mediumCount, props.Kpi.metrics.services.count.lowCount]
            setservicesSeries(servicesSeries)
            let sortableServices: any = [];
            for (const [key, value] of Object.entries(props.Kpi.metrics.services.categories)) {
                sortableServices.push([key, value]);
            }
            sortableServices.sort((a: any, b: any) => {
                return b[1] - a[1];
            });
            servicesSeriesCategory = [(sortableServices[0] as any)[0], (sortableServices[1] as any)[0], (sortableServices[2] as any)[0]];
            setservicesSeriesCategory(servicesSeriesCategory);
            servicesSeriesCategoryCount = [(sortableServices[0] as any)[1], (sortableServices[1] as any)[1], (sortableServices[2] as any)[1]];
            setservicesSeriesCategoryCount(servicesSeriesCategoryCount);
        }


        if (props.Kpi.metrics.darkeye && props.Kpi.metrics.darkeye.count !== undefined) {
            darkeyeSeries = [props.Kpi.metrics.darkeye.count.criticalCount, props.Kpi.metrics.darkeye.count.highCount, props.Kpi.metrics.darkeye.count.mediumCount, props.Kpi.metrics.darkeye.count.lowCount]
            setdarkeyeSeries(darkeyeSeries)
            let darkeyesortableServices: any = [];
            for (const [key, value] of Object.entries(props.Kpi.metrics.darkeye.categories)) {
                darkeyesortableServices.push([key, value]);
            }
            darkeyesortableServices.sort((a: any, b: any) => {
                return b[1] - a[1];
            });
            darkeyeSeriesCategory = [(darkeyesortableServices[0] as any)[0], (darkeyesortableServices[1] as any)[0], (darkeyesortableServices[2] as any)[0]];
            setdarkeyeSeriesCategory(darkeyeSeriesCategory);
            darkeyeSeriesCategoryCount = [(darkeyesortableServices[0] as any)[1], (darkeyesortableServices[1] as any)[1], (darkeyesortableServices[2] as any)[1]];
            setdarkeyeSeriesCategoryCount(darkeyeSeriesCategoryCount);
        }
    }, []);


    const toggle = (e: any, activeTab: any) => {
        console.log(e);
        console.log(activeTab)
        setactiveItem(activeTab)
    }

    if (signature === '0' || token === null || token === undefined || token === 'undefined' || token.length < 0) {
        return <Redirect to={{
            pathname: '/login',
        }} />
    }
    return (
        <>
            <MDBContainer fluid className="pt-5 pb-5" style={{ padding: "20px" }}>
                <MDBRow >
                    <MDBCol sm="12" md="6" lg="6">
                        <h4 style={{ fontWeight: 900, fontSize: "1.6rem", marginLeft: "15px", }}>Welcome back,&nbsp;<span style={{ fontWeight: 200 }}>{(localStorage.getItem("username") as any).toUpperCase()}! </span></h4>
                        <p style={{ fontSize: "11px", marginLeft: "20px", color: "darkgray" }}>Last Login 4:20 AM 11th March 2022</p>

                    </MDBCol>

                    <MDBCol sm="12" md="6" lg="6">
                        <MDBIcon className="float-right" fas icon="ellipsis-v" style={{ padding: "15px" }} />
                        <MDBBtn className="float-right" disabled
                            color="dark"
                            style={{ border: "2px solid blue-grey", borderRadius: "10px" }}>Export Summary&nbsp;&nbsp;
                            <MDBIcon fas icon="book-open" style={{ fontSize: "15px", marginLeft: "3px" }} />
                        </MDBBtn>
                    </MDBCol>


                </MDBRow>
                <hr style={{ margin: "0px 0px 20px 0px" }} />
                <MDBRow>
                    <MDBCol sm="12" md="12" lg="6">
                        <>
                            <div className="ptest" style={{ border: "1px solid rgb(237, 237, 239)" }} >
                                <h6 className="st" style={{ fontWeight: 800, backgroundColor: "rgb(237, 237, 239)" }}>Risk over time
                                    </h6>

                                <MDBView style={{ border: "1px solid #fff" }}>
                                    <MDBRow
                                        style={{ opacity: 0.5 }}
                                    >
                                        <MDBCol sm="12" md="12" lg="12">
                                            <TimeSeries />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBMask overlay="white-strong" >
                                        <p><MDBIcon style={{ color: "#2bbbad", cursor: "pointer", fontSize: "25px", padding: "2px" }} size="1x" icon="lock" /></p>
                                    </MDBMask>
                                </MDBView>

                            </div>

                        </>
                        <br />

                        <>
                            {props.iswatcher ?
                                <div className="ptest" style={{ cursor: "pointer", border: "1px solid rgb(237, 237, 239)" }} >
                                    <h6 className="st" style={{ fontWeight: 800, cursor: "pointer" }}>
                                        <span onClick={() => { props.SwitchView('domains') }}>Domains</span>
                                        <MDBIcon fas icon="arrows-alt" onClick={() => { props.SwitchView('domains') }} style={{ fontSize: "13px", marginLeft: "10px", float: "right" }} >&nbsp;&nbsp;
                                        <span style={{ fontWeight: 500 }}>Show Details&nbsp;&nbsp;</span></MDBIcon>
                                    </h6>

                                    <p className="mt-2">
                                        <br />
                                        <MDBRow style={{ padding: "0px 20px" }}>
                                            <MDBCol onClick={() => { props.SwitchView('domains') }} xs="4" sm="4" md="4" lg="4" style={{ display: "grid" }}>
                                                <div style={{ display: "-webkit-inline-box" }}>
                                                    <MDBIcon style={{ backgroundColor: "#ff8c00", fontSize: "15px", color: "white" }}
                                                        icon="check" />
                                                    <p style={{ fontSize: "12px", marginLeft: "10px" }}> <span style={{ fontSize: "15px", fontWeight: "bold" }}> {props.assets.domains.totalDomains}</span> <br /> available domains</p>
                                                </div>
                                                <div style={{ display: "-webkit-inline-box" }}>
                                                    <MDBIcon style={{ backgroundColor: "#008450", fontSize: "20px", color: "white" }}
                                                        icon="check-double" />
                                                    <p style={{ fontSize: "12px", marginLeft: "10px" }}> <span style={{ fontSize: "15px", fontWeight: "bold" }}> {props.assets.domains.totalDomains - props.assets.domains.misconfiguredDomains}</span> <br /> safe running domains</p>
                                                </div>
                                                <div style={{ display: "-webkit-inline-box" }}>
                                                    <MDBIcon style={{ backgroundColor: "#dc3912", fontSize: "20px", color: "white" }}
                                                        icon="exclamation-triangle" />
                                                    <p style={{ fontSize: "12px", marginLeft: "10px" }}> <span style={{ fontSize: "15px", fontWeight: "bold" }}> {props.assets.domains.misconfiguredDomains}</span> <br /> misconfigured domains</p>
                                                </div>
                                            </MDBCol>
                                            <MDBCol xs="1" sm="1" md="1" lg="1" ></MDBCol>
                                            <MDBCol xs="7" sm="7" md="7" lg="7" style={{ display: "grid" }}>
                                                <MDBBadge onClick={() => { props.SwitchView('domains') }} className="domainissues" style={{ maxHeight: "60px" }}>
                                                    <p style={{ marginTop: "15px", fontSize: "15px", color: "red" }}><span style={{ color: "red", fontWeight: 700, fontSize: "20px" }}> {props.assets.domains.totalMisconfigurations} </span> &nbsp;&nbsp; ongoing issues</p>
                                                </MDBBadge>
                                                <MDBBadge onClick={() => { props.SwitchView('domains') }} className="domainissues" style={{ marginTop: "20px", maxHeight: "60px" }}>
                                                    <p style={{ marginTop: "15px", fontSize: "15px", color: "green" }}><span style={{ color: "green", fontWeight: 700, fontSize: "20px" }}> 0 </span> &nbsp;&nbsp; resolved issues </p>
                                                </MDBBadge>
                                            </MDBCol>
                                        </MDBRow>
                                    </p>
                                    <br />
                                </div>
                                :
                                <div className="ptest" style={{ cursor: "pointer", border: "1px solid rgb(237, 237, 239)" }} >
                                    <h6 className="st" style={{ fontWeight: 800, cursor: "pointer" }}>
                                        <span>Domains</span>
                                    </h6>
                                    <p className="mt-2">
                                        <MDBView style={{ border: "1px solid #fff" }}>
                                            <MDBRow style={{ padding: "0px 20px", opacity: 0.4 }}>
                                                <MDBCol onClick={() => { props.SwitchView('domains') }} xs="4" sm="4" md="4" lg="4" style={{ display: "grid" }}>
                                                    <div style={{ display: "-webkit-inline-box" }}>
                                                        <MDBIcon style={{ backgroundColor: "#ff8c00", fontSize: "15px", color: "white" }}
                                                            icon="check" />
                                                        <p style={{ fontSize: "12px", marginLeft: "10px" }}> <span style={{ fontSize: "15px", fontWeight: "bold" }}> 3</span> <br /> available domains</p>
                                                    </div>
                                                    <div style={{ display: "-webkit-inline-box" }}>
                                                        <MDBIcon style={{ backgroundColor: "#008450", fontSize: "20px", color: "white" }}
                                                            icon="check-double" />
                                                        <p style={{ fontSize: "12px", marginLeft: "10px" }}> <span style={{ fontSize: "15px", fontWeight: "bold" }}> 2</span> <br /> running domains</p>
                                                    </div>
                                                    <div style={{ display: "-webkit-inline-box" }}>
                                                        <MDBIcon style={{ backgroundColor: "#dc3912", fontSize: "20px", color: "white" }}
                                                            icon="exclamation-triangle" />
                                                        <p style={{ fontSize: "12px", marginLeft: "10px" }}> <span style={{ fontSize: "15px", fontWeight: "bold" }}> 1</span> <br /> unreachable domains</p>
                                                    </div>
                                                </MDBCol>
                                                <MDBCol xs="1" sm="1" md="1" lg="1" ></MDBCol>
                                                <MDBCol xs="7" sm="7" md="7" lg="7" style={{ display: "grid" }}>
                                                    <MDBBadge onClick={() => { props.SwitchView('domains') }} className="domainissues" style={{ maxHeight: "60px" }}>
                                                        <p style={{ marginTop: "15px", fontSize: "15px", color: "red" }}><span style={{ color: "red", fontWeight: 700, fontSize: "20px" }}> {props.assets.domains.totalMisconfigurations} </span> &nbsp;&nbsp; ongoing issues</p>
                                                    </MDBBadge>
                                                    <MDBBadge onClick={() => { props.SwitchView('domains') }} className="domainissues" style={{ marginTop: "20px", maxHeight: "60px" }}>
                                                        <p style={{ marginTop: "15px", fontSize: "15px", color: "green" }}><span style={{ color: "green", fontWeight: 700, fontSize: "20px" }}> 0 </span> &nbsp;&nbsp; resolved issues </p>
                                                    </MDBBadge>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBMask overlay="white-strong" >
                                                <p><MDBIcon style={{ color: "#2bbbad", cursor: "pointer", fontSize: "25px", padding: "2px" }} size="1x" icon="lock" /></p>
                                            </MDBMask>
                                        </MDBView>
                                    </p>
                                    <br />
                                </div>}
                        </>
                        <br />
                        <>
                            <div className="ptest" style={{ cursor: "pointer", border: "1px solid rgb(237, 237, 239)" }} >
                                <h6 onClick={() => { props.SwitchView('scmwatcher') }} className="st" style={{ fontWeight: 900, cursor: "pointer", margin: "0px" }}>
                                    <span style={{ color: "black" }}>SCM WATCHER</span>
                                </h6>
                                <p style={{ margin: "0px", minHeight: "19.5vh", backgroundColor: "#1B1C1E" }}>
                                    <div style={{ padding: "15px", opacity: 0.15 }}>
                                        <MDBCardGroup deck>
                                            <MDBCard>
                                                <MDBCardBody>
                                                    <MDBCardTitle tag="h7" style={{ color: "white" }}><span style={{ color: "indianred", fontWeight: 700, fontSize: "27px" }}> 13</span> <br /> Code leaks</MDBCardTitle>
                                                </MDBCardBody>
                                            </MDBCard>
                                            <MDBCard>
                                                <MDBCardBody className="text-danger">
                                                    <MDBCardTitle tag="h7" style={{ color: "white" }}><span style={{ color: "indianred", fontWeight: 700, fontSize: "27px" }}> 5</span> <br />  Documents</MDBCardTitle>
                                                </MDBCardBody>
                                            </MDBCard>
                                            <MDBCard>
                                                <MDBCardBody className="text-danger">
                                                    <MDBCardTitle tag="h7" style={{ color: "white" }}><span style={{ color: "indianred", fontWeight: 700, fontSize: "27px" }}> 12</span> <br />  Public board</MDBCardTitle>
                                                </MDBCardBody>
                                            </MDBCard>
                                        </MDBCardGroup>
                                    </div>
                                    <br />
                                </p>
                                <div style={{ marginTop: "-16vh", color: "white", fontWeight: 400, fontSize: "13px" }}>
                                    <p style={{ textAlign: "center" }}><MDBIcon fas icon="lock" style={{ fontSize: "25px" }} /></p>

                                    <p style={{ textAlign: "center" }}>This tool is used to monitor your public forums for any leaks</p>
                                    <p style={{ textAlign: "center" }}> <MDBBtn className="saleButton" onClick={() => { props.SwitchView('scmwatcher') }}
                                        style={{ border: "2px solid blue-grey", borderRadius: "10px", fontWeight: 700, fontSize: "17px" }}>Know more
                                        </MDBBtn> </p>
                                </div>

                                <br />
                            </div>
                        </>
                    </MDBCol>
                    <MDBCol sm="12" md="12" lg="6">
                        {props.isvapt ?
                            <div className="ptest" style={{ cursor: "pointer", border: "1px solid #ededef" }} >
                                <h6 onClick={() => { props.SwitchView('pentest') }} className="st" style={{ fontWeight: 800, cursor: "pointer" }}>
                                    <span >Vulnerability Assessment</span>
                                    <MDBIcon fas icon="arrows-alt" onClick={() => { props.SwitchView('pentest') }} style={{ fontSize: "13px", marginLeft: "10px", float: "right" }} >&nbsp;&nbsp;
                                        <span style={{ fontWeight: 500 }}>Show Details&nbsp;&nbsp;</span></MDBIcon>
                                </h6>

                                <MDBRow>
                                    <MDBCol sm="6" md="6" lg="6">
                                        <Chart series={pentestSeries} width={250} height={150} /> </MDBCol>
                                    {(pentestSeriesCategoryCount.length > 0) ?
                                        <MDBCol sm="6" md="6" lg="6">
                                            <CategoryChart series={pentestSeriesCategoryCount} category={pentestSeriesCategory} width={350} height={200} /></MDBCol> : <></>}
                                </MDBRow>
                            </div>

                            :
                            <div className="ptest" style={{ cursor: "pointer" }}>
                                <h6 className="st" style={{ fontWeight: 800, cursor: "pointer" }}>
                                    <span >Vulnerability Assessment</span>
                                </h6>


                                <MDBView style={{ border: "1px solid #fff" }}>
                                    <MDBRow
                                        style={{ opacity: 0.4 }}
                                    >
                                        <MDBCol sm="6" md="6" lg="6"><Chart
                                            width={250} height={150} series={[6, 23, 14, 21]} /></MDBCol>
                                        <MDBCol sm="6" md="6" lg="6">  <CategoryChart
                                            series={[5, 3, 3]} width={350} height={200} /></MDBCol>
                                    </MDBRow>
                                    <MDBMask overlay="white-strong" >
                                        <p><MDBIcon style={{ color: "#2bbbad", cursor: "pointer", fontSize: "25px", padding: "2px" }} size="1x" icon="lock" /></p>
                                    </MDBMask>
                                </MDBView>
                            </div>
                        }
                        <br />

                        {props.iswatcher ?
                            <div className="ptest" style={{ cursor: "pointer", border: "1px solid rgb(237, 237, 239)", padding: "0px" }}>
                                <h6 onClick={() => { props.SwitchView('services') }} className="st" style={{ fontWeight: 800, cursor: "pointer" }}>
                                    <span  >Cloud services</span>
                                    <MDBIcon fas icon="arrows-alt" onClick={() => { props.SwitchView('services') }} style={{ fontSize: "13px", marginLeft: "10px", float: "right" }} >&nbsp;&nbsp;
                                        <span style={{ fontWeight: 500 }}>Show Details&nbsp;&nbsp;</span></MDBIcon>
                                </h6>
                                <p className="mt-2">
                                    <MDBRow>
                                        <MDBCol sm="6" md="6" lg="6">
                                            <Chart series={servicesSeries}
                                                color={['#0f3c4c', '#1e7898', '#2596be', '#7cc0d8']} width={250} height={150} />
                                        </MDBCol>
                                        <MDBCol sm="6" md="6" lg="6">  <CategoryChart
                                            series={servicesSeriesCategoryCount} category={servicesSeriesCategory}
                                            color={['#0f3c4c', '#1e7898', '#2596be', '#7cc0d8']}
                                            width={350} height={200}
                                        /></MDBCol>
                                    </MDBRow>
                                </p>
                            </div>
                            :
                            <div className="ptest" style={{ cursor: "pointer" }}>
                                <h6 className="st" style={{ fontWeight: 800, cursor: "pointer" }}>
                                    <span >Cloud services</span>
                                </h6>


                                <MDBView style={{ border: "1px solid #fff" }}>
                                    <MDBRow style={{ opacity: 0.4 }}>
                                        <MDBCol sm="6" md="6" lg="6">
                                            <Chart series={[6, 23, 14, 21]}
                                                color={['#0f3c4c', '#1e7898', '#2596be', '#7cc0d8']} width={250} height={150} />
                                        </MDBCol>
                                        <MDBCol sm="6" md="6" lg="6">  <CategoryChart
                                            series={[5, 3, 3]}
                                            color={['#0f3c4c', '#1e7898', '#2596be', '#7cc0d8']}
                                            width={350} height={200}
                                        /></MDBCol>
                                    </MDBRow>
                                    <MDBMask overlay="white-strong" >
                                        <p><MDBIcon style={{ color: "#2bbbad", cursor: "pointer", fontSize: "25px", padding: "2px" }} size="1x" icon="lock" /></p>
                                    </MDBMask>
                                </MDBView>
                            </div>
                        }
                        <br />
                        <>
                            {props.isdarkEyeWatcher ?
                                <div className="ptest" style={{ cursor: "pointer", border: "1px solid lightgray", backgroundColor: "silver"}}>
                                    <h6 onClick={() => { props.SwitchView('darkeyewatcher') }} className="dt" style={{ fontWeight: 900, cursor: "pointer", margin: "0px" }}>
                                        <span style={{ color: "black" }}>DARK EYE WATCHER</span>
                                    </h6>

                                    <p className="mt-2">
                                    <MDBRow>
                                        <MDBCol sm="6" md="6" lg="6">
                                            <Chart series={darkeyeSeries}
                                                color={['#b60b0a', '#e74621', '#eea002', '#d9b042']}
                                                 width={250} height={150} />
                                        </MDBCol>
                                        <MDBCol sm="6" md="6" lg="6">  <CategoryChart
                                            series={darkeyeSeriesCategoryCount} category={darkeyeSeriesCategory}
                                            color={['#b60b0a', '#e74621', '#eea002', '#d9b042']}
                                            width={300} height={150}
                                        /></MDBCol>
                                    </MDBRow>
                                </p>
                                </div>
                                :
                                <div className="ptest" style={{ cursor: "pointer", border: "1px solid lightgray" }}>
                                    <h6 className="st" style={{ fontWeight: 900, cursor: "pointer", margin: "0px" }}>
                                        <span style={{ color: "black" }}>DARK EYE WATCHER</span>
                                    </h6>

                                    <p style={{ margin: "0px", minHeight: "19.5vh", backgroundColor: "#1B1C1E" }}>
                                        <div style={{ padding: "15px", opacity: 0.15 }}>
                                            <MDBCardGroup deck>
                                                <MDBCard>
                                                    <MDBCardBody>
                                                        <MDBCardTitle tag="h7" style={{ color: "white" }}><span style={{ color: "indianred", fontWeight: 700, fontSize: "27px" }}> 9</span> <br /> Password leaks</MDBCardTitle>
                                                    </MDBCardBody>
                                                </MDBCard>
                                                <MDBCard>
                                                    <MDBCardBody className="text-danger">
                                                        <MDBCardTitle tag="h7" style={{ color: "white" }}><span style={{ color: "indianred", fontWeight: 700, fontSize: "27px" }}> 13</span> <br />  Cookie leaks</MDBCardTitle>
                                                    </MDBCardBody>
                                                </MDBCard>
                                                <MDBCard>
                                                    <MDBCardBody className="text-danger">
                                                        <MDBCardTitle tag="h7" style={{ color: "white" }}><span style={{ color: "indianred", fontWeight: 700, fontSize: "27px" }}> 6</span> <br />  P-II leaks</MDBCardTitle>
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </MDBCardGroup>
                                        </div>
                                        <br />
                                    </p>
                                    <div style={{ marginTop: "-16vh", color: "white", fontWeight: 400, fontSize: "13px" }}>
                                        <p style={{ textAlign: "center" }}><MDBIcon fas icon="lock" style={{ fontSize: "25px" }} /></p>

                                        <p style={{ textAlign: "center" }}>This tool is used to monitor dark web for any leaks. </p>
                                        <p style={{ textAlign: "center" }}> <MDBBtn className="saleButton" onClick={() => { props.SwitchView('darkeyewatcher') }}
                                            style={{ border: "2px solid blue-grey", borderRadius: "10px", fontWeight: 700, fontSize: "17px" }}>Know more
                                    </MDBBtn> </p>
                                    </div>

                                </div>
                            }
                        </>
                    </MDBCol>
                </MDBRow>

            </MDBContainer>
        </>
    )
}