import React, { useState, useEffect } from "react";
import { Redirect } from 'react-router';
import { Header } from "../../common/Header";
import {
    MDBBtn,
    MDBFreeBird,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBCardText,
    MDBCol,
    MDBContainer,
    MDBFormInline,
    MDBInput,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBEdgeHeader,
    MDBCardTitle,
    MDBProgress,
    MDBListGroup,
    MDBListGroupItem,
    MDBBreadcrumbItem,
    MDBBreadcrumb,
    MDBCollapse,
    MDBNotification,
    MDBBadge,
    MDBIcon
} from "mdbreact";
import "./style.css";
import { fetchData } from '../../services/apiConfig'
import { useWatch } from "react-hook-form";
import moment from "moment";

export const DarkeyeIssueView = (props: any) => {
    const [subDomainfullList, setsubDomainfullList] = useState([]);
    const [subDomains, setSubDomains] = useState([]);
    const [collapseID, setCollapseID] = useState("");
    const [vulnerability, setVulnerability] = useState('');
    const [isprivate, setisprivate] = useState('')
    const [isresolved, setisresolved] = useState()
    const [selectedDomain, setSelectedDomain] = useState('')
    const [selectedSort, setSelectedSort] = useState('');
    const [loading, setLoading] = useState(false);
    console.log(props)
    // Set default month and year
    const [selectedMonth, setSelectedMonth] = useState(moment().month());  // current month (0-11)
    const [selectedYear, setSelectedYear] = useState(moment().year());    // current year

    const handleMonthChange = (e: any) => {
        console.log(e.target.value)
        setSelectedMonth(e.target.value);  // Update selected month
    };

    const handleYearChange = (e: any) => {
        setSelectedYear(e.target.value);  // Update selected year
    };
    useEffect(() => {
        setisprivate(props.Domain)
        fetchData('darkeye/leaks', 'GET', "?type=" + props.Domain).then((res: any) => {
            console.log(res)
            setSubDomains(res)
        })
        fetchData('darkeye/domains', 'GET', "?accountName=" + props.Account).then((doaminres: any) => {
            console.log(doaminres)
            setsubDomainfullList(doaminres)
        })
    }, [isresolved]);

    const toggleCollapse = (collapseid: any) => {
        if (collapseid !== collapseID) {
            setCollapseID(collapseid)
        } else {
            setCollapseID("")
        }
    }
    const generateParamString = (newIssue: any, newVulnerability: any, newDomain: any, newSort: any) => {
        const baseUrl = '/';
        const type = newIssue ? `type=${newIssue}` : isprivate.length > 0 ? `type=${isprivate}` : 'type=all';
        const severity = newVulnerability && newVulnerability !== "all" ? `severity=${newVulnerability}` : newVulnerability == "all" ? '' : vulnerability.length > 0 && vulnerability != "all" ? `severity=${vulnerability}` : '';
        const domain = newDomain && newDomain !== "all" ? `domain=${newDomain}` : newDomain === "all" ? '' : selectedDomain.length > 0 && selectedDomain !== "all" ? `domain=${selectedDomain}` : '';
        const sort = newSort && newSort !== "all" ? `sort=${newSort}` : newSort === "all" ? '' : selectedSort.length > 0 && selectedSort !== "all" ? `sort=${selectedSort}` : '';
        // Filter out empty parameters and join with "&"
        const queryString = [type, severity, domain, sort].filter(Boolean).join('&');

        return queryString ? `${baseUrl}?${queryString}` : baseUrl;
    };
    const Vulnerability = async (e: any) => {
        setLoading(true);
        setVulnerability(e.target.value);
        console.log(generateParamString(null, e.target.value, null, null))
        const queryParams = generateParamString(null, e.target.value, null, null)
        fetchData('darkeye/leaks', 'GET', queryParams).then((res: any) => {
            setSubDomains(res);
            setLoading(false);
        })
    }
    const SelectDomain = async (e: any) => {
        setLoading(true);
        setSelectedDomain(e.target.value);
        console.log(generateParamString(null, null, e.target.value, null))
        const queryParams = generateParamString(null, null, e.target.value, null)
        fetchData('darkeye/leaks', 'GET', queryParams).then((res: any) => {
            setSubDomains(res)
            setLoading(false);
        })
    }
    const Sort = async (e: any) => {
        setLoading(true);
        setSelectedSort(e.target.value);
        console.log(generateParamString(null, null, null, e.target.value))
        const queryParams = generateParamString(null, null, null, e.target.value)
        fetchData('darkeye/leaks', 'GET', queryParams).then((res: any) => {
            setSubDomains(res)
            setLoading(false);
        })
    }
    const isPrivate = async (e: any) => {
        setLoading(true);
        let filter: any
        console.log(generateParamString(e.target.value, null, null, null))
        const queryParams = generateParamString(e.target.value, null, null, null)
        if (e.target) {
            setisprivate(e.target.value);
            fetchData('darkeye/leaks', 'GET', queryParams).then((res: any) => {
                setSubDomains(res)
                setLoading(false);
            })
        }
    }
    const markResolved = (e: any) => {
        fetchData('darkeye/leaks/', 'GET', e).then((res: any) => {
            setisresolved(e)
            console.log(res)
        })
    }
    const downloadReport = () => {
        fetchData('darkeye/reports/download', 'GET', "?month=09&year=2024").then((res: any) => {
            if (res.message) {
                if (res.message.indexOf("Error") !== -1) {
                    localStorage.clear();
                }
            } else {
                window.open(res.url);
            }
        })
    }
    const token = localStorage.getItem("token");
    if (token === null || token === undefined || token.length < 0) {
        return <Redirect to={{
            pathname: '/login',
        }} />
    }
    const currentYear = moment().year();
    const months = moment.months();  // Array of month names (e.g., ["January", "February", ...])
    const years = [];  // Array to hold the list of years (e.g., current year and some range before and after)

    // Populate the years array (e.g., from 2020 to the current year + 5 years)
    for (let i = moment().year() - 5; i <= moment().year(); i++) {
        years.push(i);
    }

    const dummy = ["abc", "cdf"]
    return (
        <>
            <MDBContainer fluid className="pb-5" style={{ padding: "2vw" }}>
                <MDBRow>
                    <MDBCol sm="11" md="11" lg="11" style={{ padding: "0px" }}>
                        <MDBBreadcrumb >
                            <MDBBtn className="float-left"
                                color="dark"
                                onClick={() => props.SwitchView('home')}
                                style={{ border: "2px solid blue-grey", borderRadius: "10px" }}>
                                <MDBIcon fas icon="angle-left" style={{ fontSize: "15px", marginLeft: "3px" }} />
                            &nbsp;&nbsp; Go back to dashboard&nbsp;&nbsp;
                        </MDBBtn>
                            <MDBBreadcrumbItem onClick={() => props.SwitchView('home')} style={{ cursor: "pointer", marginLeft: "20px" }} >dashboard</MDBBreadcrumbItem>
                            <MDBBreadcrumbItem onClick={() => props.SwitchView('darkeyewatcher')} style={{ cursor: "pointer" }}>darkeye</MDBBreadcrumbItem>
                            <MDBBreadcrumbItem active>{isprivate}</MDBBreadcrumbItem>
                        </MDBBreadcrumb>
                    </MDBCol>
                </MDBRow>

                <MDBRow>
                    <MDBCol sm="11" md="11" lg="11" style={{ padding: "10px 25px", border: "1px solid #ededef", backgroundColor: "#ededef" }}>
                        <MDBRow md="12" lg="12" style={{ display: "flex", justifyContent: "space-between" }}>
                            <p className='text-dark font-weight-bold' style={{ fontSize: "25px", paddingTop: "10px" }}>Issues</p>
                            {/* Month Selector */}
                            <div>
                                <select
                                    className="custom-select"
                                    style={{ width: "120px", marginTop: ".375rem" }}
                                    value={selectedMonth}
                                    onChange={handleMonthChange}
                                >
                                    {months.map((month, index) => (
                                        <option key={index} value={index}>
                                            {month}
                                        </option>
                                    ))}
                                </select>

                                {/* Year Selector */}
                                <select
                                    className="custom-select"
                                    style={{ width: "80px", marginTop: ".375rem" }}
                                    value={selectedYear}
                                    onChange={handleYearChange}
                                >
                                    {years.map((year, index) => (
                                        <option key={index} value={year}>
                                            {year}
                                        </option>
                                    ))}
                                </select>
                                <MDBBtn className="float-right"
                                    color="dark"
                                    style={{ border: "2px solid blue-grey", borderRadius: "10px", fontSize: "15px", height: "39px" }}
                                    onClick={() => {
                                        downloadReport()
                                    }}>Download report&nbsp;&nbsp;
                                    <MDBIcon fas icon="book-open" style={{ fontSize: "15px", marginLeft: "3px" }} />
                                </MDBBtn>
                            </div>
                        </MDBRow>
                        <MDBRow>
                            <div>
                                <select className="custom-select" style={{ width: "200px" }} onChange={(e: any) => isPrivate(e)} value={isprivate}>
                                    <option value="all" >Select Issue</option>
                                    <option value="Generic" >Generic</option>
                                    <option value="Cookies" >Cookies</option>
                                    <option value="plain-text-passwords" >plain-text-passwords</option>
                                    <option value="hashed-passwords" >hashed-passwords</option>
                                    <option value="PII-data" >PII-data</option>
                                    <option value="Documents" >Documents</option>
                                </select>
                            </div>
                            <div style={{ marginLeft: "25px" }}>
                                <select className="custom-select" style={{ width: "250px" }} onChange={(e: any) => SelectDomain(e)} value={selectedDomain}>
                                    <option value="all" >Select domain</option>
                                    {subDomainfullList && subDomainfullList.map((domain: any) => {
                                        return <option value={domain} > {domain}</option>
                                    })}
                                </select>
                            </div>
                            <div style={{ marginLeft: "25px" }}>
                                <select className="custom-select" style={{ width: "150px" }} onChange={(e: any) => Vulnerability(e)} value={vulnerability}>
                                    <option value="all" >Select Severity</option>
                                    <option value="critical" >Critical</option>
                                    <option value="high" >High</option>
                                    <option value="medium" >Medium</option>
                                    <option value="low" >Low</option>
                                </select>
                            </div>
                            <div style={{ marginLeft: "25px" }}>
                                <select className="custom-select" style={{ width: "200px" }} onChange={(e: any) => Sort(e)} value={selectedSort}>
                                    <option value="all" >Sort by</option>
                                    <option value="true" >Date: last indexed</option>
                                    <option value="false" >Date: first indexed</option>
                                </select>
                            </div>
                        </MDBRow>
                        <div style={{ maxHeight: "65vh", overflowY: "auto", overflowX: "hidden" }}>
                            <ul className="list-group" style={{ listStyle: "none", fontSize: "15px", marginTop: "2vw" }}>
                                <div style={{ backgroundColor: "white" }}>
                                    {loading === true ? <div className="subDomainVulnearbility">
                                        <li className="list-group-item-pentest">Loading...
                                            </li>
                                    </div> : subDomains.length > 0 ?
                                        subDomains.map((subD: any, index: any) =>
                                            <div className="subDomainVulnearbility">
                                                <li
                                                    className={subD.totalCount > 0 ? "list-group-item-pentest" : "list-group-item-pentest"}
                                                    style={{
                                                        color: subD.severity === "critical" ? "red" :
                                                            subD.severity === "high" ? "orange" :
                                                                subD.severity === "medium" ? "blue" :
                                                                    subD.severity === "low" ? "green" : "black"
                                                    }}
                                                    onClick={() =>
                                                        toggleCollapse("collapse" + index++)}>{subD.source} <span style={{ color: "black" }}>({subD.email ? subD.email : subD.url})</span>
                                                    <span
                                                        className={subD.totalCount > 0 ? "vbadge" : "vbadge"}
                                                        style={{
                                                            color: subD.severity === "critical" ? "red" :
                                                                subD.severity === "high" ? "orange" :
                                                                    subD.severity === "medium" ? "blue" :
                                                                        subD.severity === "low" ? "green" : "black"
                                                        }}
                                                    >{subD.severity} &nbsp;
                                                    <MDBIcon fas icon={collapseID !== "" ? "caret-right" : "caret-down"} /></span>
                                                </li>


                                                <MDBCollapse id={"collapse" + index} isOpen={collapseID} style={{ lineHeight: "2" }}>
                                                    {subD.source && <div><span style={{ fontWeight: "bold" }}>Source: </span>{subD.source}</div>}
                                                    {subD.domain && <div><span style={{ fontWeight: "bold" }}>Domain: </span>{subD.domain}</div>}
                                                    {subD.url && <div><span style={{ fontWeight: "bold" }}>Url: </span>{subD.url}</div>}
                                                    {subD.username && <div><span style={{ fontWeight: "bold" }}>Username: </span>{subD.username}</div>}
                                                    {subD.name && <div><span style={{ fontWeight: "bold" }}>Name: </span>{subD.name}</div>}
                                                    {subD.email && <div><span style={{ fontWeight: "bold" }}>Email: </span>{subD.email}</div>}
                                                    {subD.password && <div><span style={{ fontWeight: "bold" }}>Password: </span>{subD.password}</div>}
                                                    {subD.document && <div><span style={{ fontWeight: "bold" }}>Document: </span>{subD.document}</div>}
                                                    {subD.value && subD.value.includes('\n') && (
                                                        <div>
                                                            <span style={{ fontWeight: "bold" }}>Value: </span>
                                                            {subD.value.split('\n').map((line: any, index: any) => (
                                                                <div key={index}>{line}</div>
                                                            ))}
                                                        </div>
                                                    )}
                                                  
                                                    {subD.db_string && <div><span style={{ fontWeight: "bold" }}>Db string: </span>{subD.db_string} </div>}
                                                    {/* {subD.hashed_password && <div><span style={{ fontWeight: "bold" }}>Hashed password: </span>{subD.hashed_password}</div>} */}
                                                    {subD.type && <div><span style={{ fontWeight: "bold" }}>Type: </span>{subD.type}</div>}
                                                    {/* {subD.identifier && <div><span style={{ fontWeight: "bold" }}>Identifier: </span>{subD.identifier}</div>} */}
                                                    {subD.date_of_leak && <div><span style={{ fontWeight: "bold" }}>Date of Leak: </span>{subD.date_of_leak}</div>}
                                                    {subD.ip_address && <div><span style={{ fontWeight: "bold" }}>IP Address: </span>{subD.ip_address}</div>}
                                                    {subD.address && <div><span style={{ fontWeight: "bold" }}>Address: </span>{subD.address}</div>}


                                                    {subD.status === "pending_action" ? <div>
                                                        <MDBBadge onClick={() => {
                                                            markResolved(subD.identifier)
                                                        }} style={{ float: "right", cursor: "pointer", fontSize: "12px" }} color="warning" className="subDomainBadge" value={subD.identifier}>
                                                            Mark as resolved</MDBBadge>

                                                    </div> : <MDBBadge style={{ float: "right", fontSize: "12px" }} className="subDomainBadge" value={subD.identifier}>
                                                        Action taken</MDBBadge>
                                                    }
                                                    <br />
                                                </MDBCollapse>
                                            </div>
                                        ) : <div className="subDomainVulnearbility">
                                            <li className="list-group-item-pentest">No issues found
                                            </li>
                                        </div>}
                                </div>
                            </ul>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </>
    )
}