import React, { useState, useEffect } from "react";
import { Redirect } from 'react-router';
import { Header } from "../../common/Header";
import {
    MDBBtn,
    MDBFreeBird,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBCardText,
    MDBCol,
    MDBContainer,
    MDBFormInline,
    MDBIcon,
    MDBInput,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBEdgeHeader,
    MDBCardTitle,
    MDBProgress,
    MDBListGroup,
    MDBListGroupItem,
    MDBBreadcrumbItem,
    MDBBreadcrumb,
    MDBNotification,
    MDBLink,
    MDBBadge
} from "mdbreact";
import { useHistory } from "react-router";
import { Chart } from "react-google-charts";
import "./style.css"
import { fetchData } from '../../services/apiConfig';
import { AccountsSelector } from "./AccountsSelector";
import darkweb from "../../assets/images/darkweb.png"
import { Domain } from "./Domain";
import moment from "moment";

export const DarkeyeWatcher = (props: any) => {
    const [darkeye, setDarkeye] = useState(["purplle.com", "purplle.slack.com"]);
    const [loading, setLoading] = useState(false);

    let sortingDarkIssues: any
    const [sortedDarkIssues, setSortedDarkIssues] = useState([])
    // Set default month and year
    const [selectedMonth, setSelectedMonth] = useState(moment().month());  // current month (0-11)
    const [selectedYear, setSelectedYear] = useState(moment().year());    // current year

    const handleMonthChange = (e: any) => {
        console.log(e.target.value)
        setSelectedMonth(e.target.value);  // Update selected month
    };

    const handleYearChange = (e: any) => {
        setSelectedYear(e.target.value);  // Update selected year
    };

    useEffect(() => {
        console.log(props.Account)
        fetchData('darkeye', 'GET', "?accountName=" + props.Account).then((res: any) => {
            console.log(res)
            fetchData('darkeye/domains', 'GET', "?accountName=" + props.Account).then((doaminres: any) => {
                console.log(doaminres)
                setDarkeye(doaminres)
            })
            sortingDarkIssues = res.sort((a: any, b: any) => {
                if (a.total > b.total) {
                    return -1
                } else {
                    return 1
                }
            })
            setSortedDarkIssues(sortingDarkIssues)
            setLoading(false);
        })
    }, [props]);

    const DarkeEyeIssueViewSwitch = (e: any) => {
        console.log(e);
        props.SwitchView('darkeyeissueview', e)
    }

    const downloadReport = () => {
        fetchData('darkeye/reports/download', 'GET', `?month=${selectedMonth}&year=${selectedYear}`).then((res: any) => {
            if (res.message) {
                if (res.message.indexOf("Error") !== -1) {
                    localStorage.clear();
                }
            } else {
                window.open(res.url);
            }
        })
    }
    const currentYear = moment().year();
    const months = moment.months();  // Array of month names (e.g., ["January", "February", ...])
    const years = [];  // Array to hold the list of years (e.g., current year and some range before and after)

    // Populate the years array (e.g., from 2020 to the current year + 5 years)
    for (let i = moment().year() - 5; i <= moment().year(); i++) {
        years.push(i);
    }

    if (loading) {
        return <div>Loading...</div>; // Show a loading indicator or spinner
    }
    return (
        <>
            <MDBContainer fluid className="pb-5" style={{ padding: "2vw" }}>
                <MDBRow>
                    <MDBCol sm="11" md="11" lg="11" style={{ padding: "0px" }}>
                        <MDBBreadcrumb >
                            <MDBBtn className="float-left"
                                color="dark"
                                onClick={() => props.SwitchView('home')}
                                style={{ border: "2px solid blue-grey", borderRadius: "10px" }}>
                                <MDBIcon fas icon="angle-left" style={{ fontSize: "15px", marginLeft: "3px" }} />
                            &nbsp;&nbsp; Go back to dashboard&nbsp;&nbsp;
                        </MDBBtn>
                            <MDBBreadcrumbItem onClick={() => props.SwitchView('home')} style={{ cursor: "pointer", marginLeft: "20px" }} >dashboard</MDBBreadcrumbItem>
                            <MDBBreadcrumbItem style={{ cursor: "pointer" }}>darkeye</MDBBreadcrumbItem>
                        </MDBBreadcrumb>
                    </MDBCol>
                </MDBRow>
                <br />
                <MDBRow>
                    <MDBCol sm="11" md="11" lg="11" style={{ padding: "10px 25px", border: "1px solid #ededef" }}>
                        <MDBRow md="12" lg="12" style={{ display: "flex", justifyContent: "space-between" }}>
                            <p className='text-dark font-weight-bold' style={{ fontSize: "22px", paddingTop: "2px" }}>Domains on dark watch<span className='text-dark' style={{ fontSize: "12px", marginLeft: "10px", paddingTop: "12px" }}>Last synced {"September 27 2024, 13:11:19"}</span></p>

                            {/* Month Selector */}
                            <div>
                            <select
                                className="custom-select" 
                                style={{ width: "120px", marginTop: ".375rem" }}
                                value={selectedMonth}
                                onChange={handleMonthChange}
                            >
                                {months.map((month, index) => (
                                    <option key={index} value={index}>
                                        {month}
                                    </option>
                                ))}
                            </select>

                            {/* Year Selector */}
                            <select
                                className="custom-select" 
                                style={{ width: "80px", marginTop: ".375rem" }}
                                value={selectedYear}
                                onChange={handleYearChange}
                            >
                                {years.map((year, index) => (
                                    <option key={index} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </select>

                            <MDBBtn className="float-right"
                                color="dark"
                                style={{ border: "2px solid blue-grey", borderRadius: "10px", fontSize: "15px" }}
                                onClick={() => {
                                    downloadReport()
                                }}>Download report&nbsp;&nbsp;
                                    <MDBIcon fas icon="book-open" style={{ fontSize: "15px", marginLeft: "3px" }} />
                            </MDBBtn>
                        </div>

                        </MDBRow>
                        <br />
                        <MDBRow style={{ marginTop: "2vh" }}>
                            <MDBCol xs="4" sm="4" md="4" lg="4">
                                <div style={{ display: "inline-flex" }}>
                                    <MDBIcon style={{ backgroundColor: "#ff8c00", fontSize: "40px", color: "white" }}
                                        icon="check" />
                                    <p style={{ marginLeft: "20px" }}> <span style={{ fontSize: "25px", fontWeight: "bold" }}> {darkeye.length}</span> <br /> available domains</p>
                                </div>
                            </MDBCol>
                            <MDBCol xs="4" sm="4" md="4" lg="4">
                                <div
                                >
                                    {darkeye.map((darkdom: any, index: number) => {
                                        return (<>
                                            {console.log(darkdom)}
                                            <MDBBadge key={index} className="DomainBadge" >{darkdom} </MDBBadge>
                                            <br />
                                        </>
                                        )
                                    })}
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                <br /> <br />
                <MDBRow >
                    <MDBCol sm="11" md="11" lg="11" style={{ padding: "10px 25px", border: "1px solid #ededef" }}>
                        <div style={{ maxHeight: "48vh", overflowY: "auto", overflowX: "hidden" }}>
                            <MDBRow className="awsRow">

                                {
                                    sortedDarkIssues.map((dom: any) =>
                                        <>
                                            <MDBCol className="domainList" sm="3" md="3" lg="3"
                                                onClick={() => DarkeEyeIssueViewSwitch(dom.key)}
                                            >
                                                <div className="domainCard">
                                                    <MDBBadge className="DomainBadge" color={dom.severity === "critical" ? "danger" : dom.severity === "high" ? "warning" : dom.severity === "medium" ? "info" : "success"}>{dom.severity}</MDBBadge>
                                                    <br />
                                                    <MDBBadge className="DomainBadge" color="light">{dom.total} issues</MDBBadge>
                                                    <p className='text-dark font-weight-bold' style={{ fontSize: "20px", marginBlockEnd: "0px" }}>{dom.name}</p>
                                                </div>
                                                <p style={{ fontSize: "10px", color: "chocolate" }}>LastSynced: {dom.LastSynced}</p>
                                            </MDBCol>

                                            <MDBCol sm="1" md="1" lg="1" ></MDBCol>
                                        </>
                                    )
                                }
                            </MDBRow>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </>
    )
}